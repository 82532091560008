import { Record } from 'immutable'

const RoleRecord = Record({
  companyCode: undefined,
  customerCode: undefined,
  id: undefined,
  name: undefined,
  defaultRole: false,
})

export default RoleRecord
