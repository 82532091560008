/*
 *
 * Roles reducer
 *
 */

import { fromJS, List, Map } from 'immutable'
import { getScope } from '../ReportSchemes/functions'
import { RoleRecord } from 'records'
import {
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_ERROR,
  CREATE_ROLE,
  CREATE_ROLE_ERROR,
  CREATE_ROLE_SUCCESS,
  COPY_ROLE,
  COPY_ROLE_ERROR,
  COPY_ROLE_SUCCESS,
  EDIT_ROLE,
  EDIT_ROLE_ERROR,
  EDIT_ROLE_SUCCESS,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_ERROR,
  GET_ROLES_UNDER_CUSTOMER_SUCCESS,
  GET_ROLES_UNDER_CUSTOMER,
} from './constants'

const initialState = fromJS({
  roles: new Map(),
  error: false,
  loading: false,
})

const mapRole = (props) => {
  const { id, companyCode, customerCode, name, defaultRole } = props
  return new RoleRecord({
    customerCode,
    companyCode,
    id,
    name,
    defaultRole,
  })
}

function rolesReducer(state = initialState, action) {
  switch (action.type) {
    case COPY_ROLE_SUCCESS:
    case CREATE_ROLE_SUCCESS: {
      const scope = getScope({
        companyCode: action.companyCode,
        customerCode: action.customerCode,
      })
      return state
        .set('loading', false)
        .updateIn(['roles', scope, 'roles'], (roles) => {
          return new List([...roles].concat([mapRole(action)]))
        })
    }

    case DELETE_ROLE_SUCCESS: {
      const scope = getScope({
        companyCode: action.companyCode,
        customerCode: action.customerCode,
      })
      return state
        .set('loading', false)
        .updateIn(['roles', scope, 'roles'], (roles) => {
          return roles.filter(
            (role) =>
              role.id !== action.roleId ||
              (role.companyCode !== action.companyCode &&
                role.customerCode !== action.customerCode)
          )
        })
    }

    case EDIT_ROLE_SUCCESS: {
      const scope = getScope({
        companyCode: action.companyCode,
        customerCode: action.customerCode,
      })
      return state
        .updateIn(['roles', scope, 'roles'], (roles) => {
          return roles.map((role) => {
            if (role.id === action.id) {
              return role.set('name', action.name)
            }
            return role
          })
        })
        .set('loading', false)
    }
    case GET_ROLES_UNDER_CUSTOMER:
    case COPY_ROLE:
    case EDIT_ROLE:
    case DELETE_ROLE:
    case CREATE_ROLE:
    case GET_ROLES:
      return state.set('loading', true).set('error', false)

    case GET_ROLES_UNDER_CUSTOMER_SUCCESS: {
      state = state.set('loading', false)
      return action.roles.reduce((acc, role) => {
        const scope = getScope({
          companyCode: role.companyCode,
          customerCode: role.customerCode,
        })
        const roleRecord = mapRole(role)
        return acc.updateIn(['roles', scope, 'roles'], (roles) => {
          return roles
            ? new List(
                [...roles]
                  .filter((role) => role.id !== roleRecord.id)
                  .concat([roleRecord])
              )
            : List([roleRecord])
        })
      }, state)
    }

    case GET_ROLES_SUCCESS:
      const { customerCode, companyCode, roles } = action
      const scope = getScope({ companyCode, customerCode })
      return state
        .set('loading', false)
        .setIn(['roles', scope, 'roles'], List(roles.map(mapRole)))

    case COPY_ROLE_ERROR:
    case EDIT_ROLE_ERROR:
    case DELETE_ROLE_ERROR:
    case CREATE_ROLE_ERROR:
    case GET_ROLES_ERROR:
      return state.set('loading', false).set('error', action.error)

    default:
      return state
  }
}

export default rolesReducer
