import { List, Record } from 'immutable'

const UserRecord = Record({
  aadUserEmail: '',
  aadUserId: undefined,
  lemonsoftUserId: undefined,
  finvoicerEmail: undefined,
  id: undefined,
  name: '',
  userName: '',
  email: '',
  defaultCompanyId: undefined,
  defaultPage: undefined,
  validFrom: undefined,
  validTo: undefined,
  theme: '',
  subUsers: List(),
  isActive: true,
  userType: '',
})

export default UserRecord
