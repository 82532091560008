/**
 *
 * SearchBar
 *
 */

import PropTypes, { oneOfType } from 'prop-types'

import React, { useState, useLayoutEffect, useCallback } from 'react'
import { FormControl } from 'react-bootstrap'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { injectIntl, intlShape } from 'react-intl'
import usePrevious from 'utils/hooks/usePrevious'
import { InputGroup, FormGroup, Button, ButtonGroup } from 'react-bootstrap'
import Icon from 'components/Icon'

import styles from './styles.module.scss'

import messages from './messages'
import { isEqual } from 'lodash'

const propTypes = {
  data: oneOfType([ImmutablePropTypes.list.isRequired, PropTypes.array]),
  intl: intlShape.isRequired,
  onSearch: PropTypes.func,
  onUpdateSearch: PropTypes.func,
  placeholderText: PropTypes.string,
  searchFields: PropTypes.array.isRequired,
}

const SearchBar = ({
  data,
  intl: { formatMessage },
  onSearch,
  onUpdateSearch,
  placeholderText,
  searchFields,
  filteredData,
}) => {
  const [searchValue, setSearchValue] = useState('')
  const previousData = usePrevious(data)

  const dataSize = data?.size ?? data?.length
  const filteredDataSize = filteredData?.size ?? filteredData?.length

  const handleChange = useCallback(
    (event) => {
      const value = event.target.value

      if (onSearch) {
        onSearch(
          data.filter((item) =>
            searchFields.some(
              (key) =>
                item[key] &&
                item[key].toUpperCase().includes(value.toUpperCase())
            )
          ),
          value
        )
      }

      if (onUpdateSearch) {
        const filterFunction = (dataToFilter) =>
          dataToFilter.filter((item) =>
            searchFields.some(
              (key) =>
                item[key] &&
                item[key]
                  .toString()
                  .toUpperCase()
                  .includes(value.toUpperCase())
            )
          )
        onUpdateSearch(filterFunction)
      }

      setSearchValue(value)
    },
    [onSearch, onUpdateSearch, data, searchFields]
  )

  useLayoutEffect(() => {
    if (!isEqual(previousData, data)) handleChange({ target: { value: '' } })
  }, [data, previousData, handleChange, filteredData, searchValue])

  return (
    <FormGroup className={styles.searchBar} id="searchbar">
      <InputGroup>
        <FormControl
          className={styles.input}
          onChange={handleChange}
          placeholder={placeholderText || formatMessage(messages.search)}
          type="text"
          value={searchValue}
        />
        <InputGroup.Text className={styles.searchMatch}>
          <>
            {searchValue === ''
              ? `${dataSize} / ${dataSize}`
              : `${filteredDataSize} / ${dataSize}`}
            <span className={styles.divider} />
          </>
        </InputGroup.Text>
        <ButtonGroup className={styles.icons}>
          <Button
            variant="default"
            disabled={searchValue?.length < 1}
            onClick={() => {
              handleChange({ target: { value: '' } })
            }}
          >
            <Icon name="remove" />
          </Button>
        </ButtonGroup>
      </InputGroup>
    </FormGroup>
  )
}

SearchBar.propTypes = propTypes

export default injectIntl(SearchBar)
