import Icon from 'components/Icon'
import React, { useState } from 'react'
import { Form, Table } from 'react-bootstrap'
import LoadingIndicator from 'components/LoadingIndicator'

import styles from './styles.module.scss'

/*
CommonListing component takes in 4 props:
  - columns: List of objects. Each object has Label for column and Sorter-function to handle sorting based on column values
    - Example of a column:
      {
        label: formatMessage(messages.name),
        sorter: (budgets) =>
          budgets.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          ),
        alignRight: true,
        default: true,
      }
  - dataList: List of data to be rendered (budgets, users etc.).
  - dataRow: Handles rendering for a single row in Parent component.
  - dataTestingId
  - handleRowClick: Optional function to handle row click
*/

/*
CommonListing component takes in 4 props:
  - columns: List of objects. Each object has Label for column and Sorter-function to handle sorting based on column values
  - dataList: List of data to be rendered (budgets, users etc.).
  - dataRow: Handles rendering for a single row in Parent component.
  - dataTestingId
*/

const CommonListing = (props) => {
  const {
    columns,
    dataList,
    tableRowRender,
    dataTestingId,
    isLoading,
    onSorttingChange,
    footer,
    bordered,
    setSelectedIds,
    useHover = true,
    selectedIds,
    handleRowClick,
    tableClassName,
  } = props
  const [columnIndex, setSorter] = useState(
    columns.findIndex((element) => element.hasOwnProperty('default')) || 0
  )
  const column = columns[columnIndex !== -1 ? columnIndex : 0]
  const [isReversed, setIsReversed] = useState(false)
  const sortedData =
    column?.sorter && dataList ? column?.sorter(dataList, isReversed) : dataList

  const sortedDataWithPossibleReverseApplied =
    isReversed && !column.manualReverse ? sortedData.reverse() : sortedData

  const bulkOperations = selectedIds && setSelectedIds
  const tableHeaderWithIcon = (clickedLabel) => {
    if (clickedLabel.skip) return
    // This is to disable unnecessary sorting options for eg. buttongroups. So we render just the title of the column
    if (clickedLabel.sorter === undefined) {
      return (
        <th key={clickedLabel.label}>
          <div
            className={
              clickedLabel.alignRight ? styles.rightAlignedWithNoCursor : ''
            }
          >
            {clickedLabel.label}
          </div>
        </th>
      )
    }
    return (
      <th
        key={clickedLabel.label}
        onClick={() => {
          if (onSorttingChange) onSorttingChange()
          if (clickedLabel.label === column.label) {
            setIsReversed(!isReversed)
          } else {
            const labelIndex = columns.findIndex(
              (element) => element.label === clickedLabel.label
            )
            setSorter(labelIndex)
            setIsReversed(false)
          }
        }}
      >
        <div
          className={
            clickedLabel.headerStyle
              ? `${styles['custom' + clickedLabel.headerStyle]}`
              : clickedLabel.alignRight
              ? styles.rightAligned
              : styles.leftAligned
          }
        >
          {clickedLabel.label}
          <div className={styles.labelItems}>
            <Icon
              className={
                !isReversed && column.label === clickedLabel.label
                  ? styles.caretActive
                  : styles.caretDeactive
              }
              name="caret-up"
              set="fa"
            />
            <Icon
              className={
                isReversed && column.label === clickedLabel.label
                  ? styles.caretActive
                  : styles.caretDeactive
              }
              name="caret-down"
              set="fa"
            />
          </div>
        </div>
      </th>
    )
  }

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <Table
      bordered={bordered}
      data-testing-id={dataTestingId}
      hover={useHover}
      className={tableClassName}
    >
      <thead className={styles.tableHead}>
        <tr>
          {bulkOperations && (
            <td>
              <Form.Check
                checked={selectedIds?.length === dataList?.size}
                onChange={() =>
                  setSelectedIds(
                    selectedIds?.length === dataList?.size
                      ? []
                      : dataList.map((data) => data.id).toJS()
                  )
                }
              ></Form.Check>
            </td>
          )}
          {columns?.map((column) => tableHeaderWithIcon(column))}
        </tr>
      </thead>
      <tbody>
        {sortedDataWithPossibleReverseApplied?.map((data, index) => {
          return (
            <tr
              key={index}
              {...(handleRowClick && {
                onClick: () => handleRowClick(data.id),
              })}
            >
              {bulkOperations && (
                <td>
                  <Form.Check
                    checked={selectedIds?.includes(data.id)}
                    onChange={() => {
                      const ids = selectedIds?.includes(data.id)
                        ? selectedIds?.filter((id) => id !== data.id)
                        : [...selectedIds, data.id]
                      setSelectedIds(ids)
                    }}
                  />
                </td>
              )}
              {tableRowRender(data, index)}
            </tr>
          )
        })}
      </tbody>
      {footer}
    </Table>
  )
}

export default CommonListing
