import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import * as yup from 'yup'

import { Modal } from 'react-bootstrap'
import Form from 'react-formal'
import Button from 'components/Button'
import FormField from 'components/FormField'
import messages from './messages'

const createUserSchemaIntl = (email, formatMessage) => {
  return yup.object({
    email: yup
      .string()
      .email(formatMessage(messages.emailValidationErrorMessage))
      .default(email)
      .required(formatMessage(messages.emailRequired)),
  })
}

const InviteUserDlg = (props) => {
  const { onToggleShowInviteUserDlg, onHandleInviteUser, user, intl } = props
  const schema = createUserSchemaIntl(user.email, intl.formatMessage)
  const [form, setForm] = useState(schema.default())

  const handleSubmit = () => {
    onHandleInviteUser(user, form.email)
  }

  return (
    <Modal show={true} onHide={onToggleShowInviteUserDlg}>
      <Modal.Header closeButton>
        <Modal.Title>Invite User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          schema={schema}
          value={form}
          onChange={(form) => setForm(form)}
          onSubmit={handleSubmit}
        >
          <FormField
            label="Email"
            name="email"
            placeholder="Enter email"
            type="text"
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onToggleShowInviteUserDlg}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Invite
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
InviteUserDlg.propTypes = {
  onToggleShowInviteUserDlg: PropTypes.func.isRequired,
  onHandleInviteUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
}
export default injectIntl(InviteUserDlg)
