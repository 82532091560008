/*
 * SearchBar Messages
 *
 * This contains all the text for the SearchBar component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  search: {
    id: 'app.components.SearchBar.search',
    defaultMessage: 'Search',
  },
})
