export const getAccountType = (user) => {
  if (user.userType === 'Integration') {
    return 'Integration account'
  }
  var types = []
  if (user.aadUserId) {
    types.push('Work or school account')
  }
  if (user.lemonsoftUserId) {
    types.push('Lemonsoft account')
  }
  if (user.finvoicerEmail) {
    types.push('Finvoicer account')
  }
  if (types.length === 0) {
    return 'Password'
  }
  return types.join(', ')
}
