/*
 *
 * Users
 *
 */

import PropTypes from 'prop-types'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import moment from 'moment'
import { Container, ButtonGroup } from 'react-bootstrap'
import Button, { CollapseButton, DeleteButton } from 'components/Button'
import ErrorComponent from 'components/ErrorComponent'
import CommonHeader from 'components/CommonHeader'
import CommonListing from 'components/CommonListing'
import LoadingIndicator from 'components/LoadingIndicator'
import InviteUserDlg from './InviteUserDlg.jsx'

import selectUsers, {
  selectError,
  selectFilteredUsers,
  selectLoading,
} from './selectors.js'
import {
  clearFilter,
  deleteAADUser,
  deleteUser,
  filterUsers,
  getAllUsers,
  inviteUser,
  unlinkAADUser,
} from './actions.js'
import messages from './messages.js'
import withRouterProps from 'utils/withRouterProps'

import useAD from 'utils/featureFlagReaders/useAD'
import styles from './styles.module.scss'
import { List } from 'immutable'
import { getAccountType } from './functions.js'

const formatDate = (date) => (date ? moment(date).format('DD.MM.YYYY') : '-')

export const flattenArray = (users) => {
  return users.flatMap((user) => {
    if (user.subUsers) {
      return List([user, ...user.subUsers])
    }
    return List([user])
  })
}

export const AllUsers = (props) => {
  const {
    children,
    params: { role, companyCode, customerCode },
    intl: { formatMessage },
  } = props

  const [hasFetched, setHasFetched] = useState(false)
  const [showInviteUserDlg, setShowInviteUserDlg] = useState(false)
  const [userToInvite, setUserToInvite] = useState({})
  const dispatch = useDispatch()

  const filteredUsers = useSelector(selectFilteredUsers())
  const loading = useSelector(selectLoading())
  const error = useSelector(selectError())
  const users = useSelector(selectUsers)
  const [openAADUserIds, setOpenAADUsersIds] = useState([])

  useEffect(() => {
    dispatch(getAllUsers())
    dispatch(clearFilter())
    setHasFetched(true)
  }, [role, customerCode, companyCode, dispatch])

  const handleDeleteUser = (userId) => {
    dispatch(deleteUser(userId, customerCode, companyCode, role))
  }

  const handleDeleteAADUser = (userId) => {
    dispatch(deleteAADUser(userId))
  }

  const handleInviteUser = (user, email) => {
    setShowInviteUserDlg(false)
    dispatch(inviteUser(user, email))
  }
  const handleUnlinkAADUser = (userId, allUsersView) => {
    dispatch(unlinkAADUser(userId, allUsersView))
  }

  const data = flattenArray(users)

  const handleFilterAllUsers = (filteredUsers) => {
    const fileteredUsersIds = filteredUsers.map(
      (filteredUser) => filteredUser.aadUserId || filteredUser.id
    )
    const filteredAADUsers = users.filter((aadUser) =>
      fileteredUsersIds.contains(aadUser.id)
    )
    if (filteredUsers.size === data.size) setOpenAADUsersIds([])
    else setOpenAADUsersIds(fileteredUsersIds)
    dispatch(filterUsers(filteredAADUsers))
  }

  const isExpanded = (id) => openAADUserIds.includes(id)
  const handleExpand = (aadUserId) => {
    if (isExpanded(aadUserId)) {
      setOpenAADUsersIds(openAADUserIds.filter((id) => id !== aadUserId))
    } else {
      setOpenAADUsersIds([...openAADUserIds, aadUserId])
    }
  }

  if (children) {
    return children
  } else if (error) {
    return <ErrorComponent {...error} />
  } else if (loading || !hasFetched) {
    return <LoadingIndicator />
  }

  const displayedUsers = filteredUsers || users
  const searchFields = ['name', 'email']
  const filterFunction = handleFilterAllUsers

  const columns = [
    {
      label: formatMessage(messages.tableName),
      sorter: (merged, isReversed) => {
        const sortedList = merged.sort((a, b) => {
          const compareResult = compareValues(a, b, isReversed)
          if (compareResult !== 0) return compareResult
          if (a.name?.toLowerCase() < b.name?.toLowerCase())
            return isReversed ? 1 : -1
          if (a.name?.toLowerCase() > b.name?.toLowerCase())
            return isReversed ? -1 : 1
          return 0
        })
        return sortedList
      },
      default: true,
      manualReverse: useAD,
    },
    {
      label: formatMessage(messages.tableEmail),
      sorter: (merged, isReversed) => {
        const sortedList = merged.sort((a, b) => {
          const compareResult = compareValues(a, b, isReversed)
          if (compareResult !== 0) return compareResult
          if (a.email?.toLowerCase() < b.email?.toLowerCase())
            return isReversed ? 1 : -1
          if (a.email?.toLowerCase() > b.email?.toLowerCase())
            return isReversed ? -1 : 1
          return 0
        })
        return sortedList
      },
      manualReverse: useAD,
    },
    {
      label: 'Type',
      sorter: (merged) => {
        const sortedList = merged.sort((a, b) => {
          const aType = getAccountType(a)
          const bType = getAccountType(b)
          return aType > bType ? 1 : -1
        })
        return sortedList
      },
    },
    {
      label: formatMessage(messages.tableValidFrom),
      sorter: (merged, isReversed) => {
        const sortedList = merged.sort((a, b) => {
          const compareResult = compareValues(a, b, isReversed)
          if (compareResult !== 0) return compareResult
          if (a.validFrom === null) return isReversed ? -1 : 1
          if (b.validFrom === null) return isReversed ? 1 : -1
          if (a.validFrom === b.validFrom) return 0
          return a.validFrom > b.validFrom ? 1 : -1
        })
        return sortedList
      },
      manualReverse: useAD,
    },
    {
      label: formatMessage(messages.tableValidTo),
      sorter: (merged, isReversed) => {
        const sortedList = merged.sort((a, b) => {
          const compareResult = compareValues(a, b, isReversed)
          if (compareResult !== 0) return compareResult
          if (a.validTo === null) return isReversed ? -1 : 1
          if (b.validTo === null) return isReversed ? 1 : -1
          if (a.validTo === b.validTo) return 0
          return a.validTo > b.validTo ? 1 : -1
        })
        return sortedList
      },
      manualReverse: useAD,
    },
    {
      label: formatMessage(messages.tableActions),
      alignRight: true,
    },
  ]

  function compareValues(a, b, isReversed) {
    if (!a?.sortId && b?.sortId) return 1
    if (a?.sortId && !b?.sortId) return -1
    if (a?.sortId < b?.sortId) return isReversed ? 1 : -1
    if (a?.sortId > b?.sortId) return isReversed ? -1 : 1
    if (a?.isParent && !b?.isParent) return -1
    if (b?.isParent && !a?.isParent) return 1
    return 0
  }

  const userRow = (user) => {
    if (user?.isSubUser)
      return (
        <React.Fragment key={user.id}>
          <td id={styles.subRow}>{user.name}</td>
          <td>{user.email}</td>
          <td>Finazilla user</td>
          <td>{formatDate(user.validFrom)}</td>
          <td>{formatDate(user.validTo)}</td>
          <td className={styles.btnCell}>
            <ButtonGroup size="sm" className="pull-right">
              <>
                <Button
                  option="default"
                  onClick={() => {
                    handleUnlinkAADUser(user.id, true)
                  }}
                  data-testing-id="users-unlink"
                >
                  <FormattedMessage {...messages.unlink} />
                </Button>
                <DeleteButton
                  option="default"
                  onClick={() => handleDeleteUser(user.id)}
                  data-testing-id="users-delete-finazilla-account"
                  deleteModalMessage={formatMessage(
                    messages.deleteModalMessageWithReport
                  )}
                  useCheckbox={true}
                />
              </>
            </ButtonGroup>
          </td>
        </React.Fragment>
      )
    return (
      <React.Fragment>
        <td>
          {user.subUsers?.length > 0 && (
            <CollapseButton
              name={isExpanded(user.id) ? 'menu-down' : 'menu-right'}
              size="xs"
              onClick={() => handleExpand(user.id)}
            />
          )}{' '}
          {user?.name}{' '}
          {user?.subUsers?.length > 0 && (
            <span
              className={styles.appendix}
            >{`| ${user.subUsers?.length} Profiles`}</span>
          )}{' '}
        </td>
        <td>{user.email}</td>
        <td>{getAccountType(user)}</td>
        <td>{formatDate(user.validFrom)}</td>
        <td>{formatDate(user.validTo)}</td>
        <td className={styles.buttonGroupColumn}>
          <ButtonGroup className={styles.buttonGroupAlignment} size="sm">
            <>
              {!user.aadUserId && (
                <Button
                  option="default"
                  onClick={() => {
                    setShowInviteUserDlg(true)
                    setUserToInvite(user)
                  }}
                  data-testing-id="users-invite"
                  disabled={!!user.aadUserId}
                >
                  <FormattedMessage {...messages.invite} />
                </Button>
              )}
              {!!user.aadUserId && (
                <DeleteButton
                  option="default"
                  onClick={() => handleDeleteAADUser(user.aadUserId)}
                  data-testing-id="users-delete-ad-account"
                  deleteModalMessage={formatMessage(
                    messages.deleteModalMessage
                  )}
                  disabled={!user.aadUserId}
                >
                  <FormattedMessage {...messages.adAccount} />
                </DeleteButton>
              )}
              {!user.aadUserId && (
                <DeleteButton
                  option="default"
                  onClick={() => handleDeleteUser(user.id)}
                  data-testing-id="users-delete-finazilla-account"
                  disabled={!!user.aadUserId}
                  deleteModalMessage={formatMessage(
                    messages.deleteModalMessageWithReport
                  )}
                  useCheckbox={true}
                />
              )}
            </>
          </ButtonGroup>
        </td>
      </React.Fragment>
    )
  }

  const usersWithSubUsers = displayedUsers
    .filter((user) => user.subUsers.length !== 0)
    .toJS()

  const usersWithNoSubUsers = displayedUsers
    .filter((user) => user.subUsers.length === 0)
    .toJS()

  const flattenUsersWithUSubUsers = usersWithSubUsers.flatMap((user) => {
    const userWithSortId = {
      ...user,
      sortId: `${user.aadUserEmail}-${user.id}`,
      isParent: true,
    }
    if (!openAADUserIds.includes(user.id)) {
      return [userWithSortId]
    }
    const relevantSubUserRows = user.subUsers.map((subUser) => ({
      ...subUser,
      sortId: `${user.aadUserEmail}-${user.id}`,
      isSubUser: true,
      parentEmail: user.aadUserEmail,
    }))

    return [userWithSortId, ...relevantSubUserRows]
  })

  const merged = [...usersWithNoSubUsers, ...flattenUsersWithUSubUsers]

  const getHeader = () => {
    return formatMessage(messages.headerAllUsers)
  }

  return (
    <Container className="users-container">
      <CommonHeader
        headerMessage={getHeader()}
        allowSearchbar={true}
        headerTestingId="users-header"
        hideButton={true}
        buttonTestingId="create-user"
        data={data}
        onSearch={filterFunction}
        searchFields={searchFields}
        filteredData={displayedUsers}
      />
      {showInviteUserDlg && (
        <InviteUserDlg
          onToggleShowInviteUserDlg={() => setShowInviteUserDlg(false)}
          onHandleInviteUser={handleInviteUser}
          user={userToInvite}
        />
      )}
      <CommonListing
        columns={columns}
        dataList={merged}
        tableRowRender={userRow}
        dataTestingId={'users-list'}
      />
    </Container>
  )
}

AllUsers.propTypes = {
  children: PropTypes.node,
  params: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
}

export default withRouterProps(injectIntl(AllUsers))
