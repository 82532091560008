/**
 *
 * CommonHeader
 *
 */

import PropTypes from 'prop-types'

import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Helmet } from 'react-helmet-async'
import {
  Row,
  Col,
  Badge,
  ButtonGroup as BsButtonGroup,
  Dropdown,
} from 'react-bootstrap'
import { SuccessButton } from 'components/Button'
import SearchBar from 'components/SearchBar'

import styles from './styles.module.scss'
import ButtonGroup from 'components/ButtonGroup'

const CommonHeader = ({
  buttonTestingId,
  headerTestingId,
  onSearch,
  data,
  useBetaLabel = false,
  headerMessage,
  subHeaderMessage,
  searchFields,
  searchPlaceholderText,
  onSearchCleared,
  disableButton,
  hideButton,
  buttonMessage,
  onClickButton,
  subHeaderLink,
  filteredData,
  allowSearchbar,
  secondaryButton,
  secondButtonMessage,
  onClickSecondButton,
}) => {
  const showSearchBar = data && onSearch && searchFields && !hideButton

  const successButtonOffset = showSearchBar ? '' : 3

  const handleFormButtonClick = (index) => {
    if (Array.isArray(onClickButton)) onClickButton[index]()
    else onClickButton()

    if (onSearch) onSearch(data)
  }

  return (
    <Row>
      <Col
        className={subHeaderLink ? styles.inlineBlock : styles.headerWrapper}
      >
        <Helmet title={headerMessage} />
        <h1
          className={subHeaderLink ? styles.inlineBlock : ''}
          data-testing-id={headerTestingId}
        >
          {headerMessage}
          {useBetaLabel && (
            <Badge
              style={{
                backgroundColor: '#774b98',
                marginBottom: '2px',
                marginLeft: '5px',
                fontSize: 'small',
                position: 'relative',
                top: '-12px',
              }}
            >
              Beta
            </Badge>
          )}
        </h1>
        {subHeaderMessage && (
          <h4 className={subHeaderLink ? styles.inlineBlock : ''}>
            {subHeaderMessage}
          </h4>
        )}
        {subHeaderLink && (
          <h4 className={subHeaderLink ? styles.inlineBlock : ''}>
            {subHeaderLink}
          </h4>
        )}
      </Col>
      <Col className={styles.actionsAligned}>
        {(allowSearchbar || showSearchBar) && (
          <span md={3} className={styles.searchBarWrapper}>
            <SearchBar
              data={data}
              filteredData={filteredData}
              onSearch={onSearch}
              searchFields={searchFields}
              placeholderText={searchPlaceholderText}
              onFilterCleared={onSearchCleared}
            />
          </span>
        )}
        {!hideButton && secondaryButton && (
          <span className={styles.secondaryButton}>
            <React.Fragment key={secondaryButton}>
              {secondaryButton}
            </React.Fragment>
          </span>
        )}
        {!hideButton && (
          <span
            md={{ span: 2, offset: successButtonOffset }}
            className={styles.successButtonWrapper}
          >
            <ButtonGroup useMargin>
              {secondButtonMessage && (
                <SuccessButton
                  onClick={onClickSecondButton}
                  data-testing-id={buttonTestingId}
                  disabled={disableButton}
                  className={styles.secondarySuccessButton}
                >
                  {secondButtonMessage}
                </SuccessButton>
              )}
              {Array.isArray(buttonMessage) ? (
                <>
                  <Dropdown as={BsButtonGroup} align={'end'}>
                    <SuccessButton
                      onClick={() => handleFormButtonClick(0)}
                      data-testing-id={buttonTestingId}
                      disabled={disableButton}
                    >
                      {buttonMessage[0]}
                    </SuccessButton>
                    <Dropdown.Toggle
                      split
                      disabled={disableButton}
                      variant="success"
                      aria-label="Split button toggle"
                    />
                    <Dropdown.Menu
                      variant="success"
                      className={styles.splitMenu}
                    >
                      {buttonMessage.slice(1).map((message, index) => (
                        <SuccessButton
                          key={index}
                          onClick={() => handleFormButtonClick(index + 1)}
                          data-testing-id={buttonTestingId}
                          disabled={disableButton}
                          className={styles.splitButton}
                        >
                          {message}
                        </SuccessButton>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              ) : (
                <SuccessButton
                  onClick={handleFormButtonClick}
                  data-testing-id={buttonTestingId}
                  disabled={disableButton}
                >
                  {buttonMessage}
                </SuccessButton>
              )}
            </ButtonGroup>
          </span>
        )}
      </Col>
    </Row>
  )
}

CommonHeader.defaultProps = {
  headerMessage: '',
}

CommonHeader.propTypes = {
  headerMessage: PropTypes.string,
  subHeaderMessage: PropTypes.string,
  buttonMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  onClickButton: PropTypes.oneOfType([PropTypes.func, PropTypes.array])
    .isRequired,
  hideButton: PropTypes.bool.isRequired,
  data: ImmutablePropTypes.list,
  onSearch: PropTypes.func,
  onSearchCleared: PropTypes.func,
  searchFields: PropTypes.array,
  searchPlaceholderText: PropTypes.string,
  buttonTestingId: PropTypes.string,
  headerTestingId: PropTypes.string,
}

export default CommonHeader
